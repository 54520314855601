@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&family=Rakkas&display=swap');

body {
  font-family: "Jost", sans-serif;
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

canvas:hover{
  cursor: grab;
}

.container {
  @apply max-w-[1366px];
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  @apply opacity-100 bg-transparent;
}

.border-gradient {
  @apply before:absolute before:top-0 before:right-0 before:left-0 before:bottom-0 before:border before:border-transparent before:rounded-full before:blur-[28px] before:backdrop-blur-[30px];
  background: linear-gradient(#292524, #292524) padding-box, linear-gradient(92.76deg, #221B12 -0.76%, #CAB175 13.21%, #292314 107.45%) border-box;
}

.ql-editor {
  font-family: 'Serif', sans-serif;
  padding: 0px !important;
  line-height: normal !important;
  /* font-size: 20pt; */
  /* white-space: normal !important; */
}

.ql-container.ql-snow{
  border: 2px dotted transparent !important;
  @apply bg-transparent duration-500;
}

.ql-container.ql-snow:hover{
  border: 2px dotted #b9b9b9 !important;
}

.ql-toolbar.ql-snow{
  @apply bg-gray-300 bg-opacity-80 invisible;
  border: 0px !important;
}

.ql-picker .ql-picker-label,
.ql-formats .ql-align {
  @apply hover:bg-white bg-opacity-20 rounded-md duration-75 hover:shadow-sm;
}

.ql-font .ql-picker-label::before {
  @apply overflow-hidden w-full pr-2 whitespace-nowrap text-ellipsis;
}

.ql-editor::before {
  color: #b9b9b9 !important;
}

.ql-editor:focus::before {
  color: #c3c3c3 !important;
}
.ql-picker-label.ql-active{
  overflow: hidden;
}

.ql-snow .ql-picker.ql-font{
  width: 176px !important;
}

.ql-snow .ql-picker.ql-size{
  width: 80px !important;
}

@font-face {
  font-family: 'Serif';
  src: url('/src/assets/fonts/PT_Serif/PTSerif-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url('/src/assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Arimo';
  src: url('/src/assets/fonts/Arimo/Arimo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ArimoBold';
  src: url('/src/assets/fonts/Arimo/Arimo-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ArimoItalic';
  src: url('/src/assets/fonts/Arimo/Arimo-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'ArimoBoldItalic';
  src: url('/src/assets/fonts/Arimo/Arimo-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'CinzelRegular';
  src: url('/src/assets/fonts/Cinzel/Cinzel-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'CinzelBold';
  src: url('/src/assets/fonts/Cinzel/Cinzel-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoRegular';
  src: url('/src/assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoBold';
  src: url('/src/assets/fonts/Nunito/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoLight';
  src: url('/src/assets/fonts/Nunito/Nunito-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'NunitoLightItalic';
  src: url('/src/assets/fonts/Nunito/Nunito-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'MerriweatherRegular';
  src: url('/src/assets/fonts/Merriweather/Merriweather-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MerriweatherBold';
  src: url('/src/assets/fonts/Merriweather/Merriweather-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratBold';
  src: url('/src/assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ArchivoSemiCondensedRegular';
  src: url('/src/assets/fonts/Archivo/Archivo-SemiCondensed-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ArchivoSemiCondensedBold';
  src: url('/src/assets/fonts/Archivo/Archivo-SemiCondensed-Bold.ttf') format('truetype');
}

.ql-color.ql-picker .ql-picker-options .ql-picker-item[data-value="white"] {
  border: 1px solid black;
}

#ql-picker-options-2{
  width: max-content;
}

.ql-toolbar.ql-snow .ql-formats{
  margin-right: 2px !important;
}

/* to change the text size */
.ql-picker.ql-size .ql-picker-label::before,
.ql-picker.ql-size .ql-picker-item::before {
  content: attr(data-value) !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Serif"]::before
{
    font-family: "Serif";
    content: "Serif" !important;
}
.ql-font-Serif {
  font-family: "Serif";
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before
{
    font-family: "Roboto";
    content: "Roboto" !important;
}
.ql-font-Roboto {
  font-family: "Roboto";
}

.ql-picker.ql-font .ql-picker-label[data-value="Arimo"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Arimo"]::before
{
    font-family: "Arimo";
    content: "Arimo" !important;
}
.ql-font-Arimo {
  font-family: "Arimo";
}

.ql-picker.ql-font .ql-picker-label[data-value="ArimoBold"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="ArimoBold"]::before
{
    font-family: "ArimoBold";
    content: "Arimo Bold" !important;
}
.ql-font-ArimoBold {
  font-family: "ArimoBold";
}

.ql-picker.ql-font .ql-picker-label[data-value="ArimoItalic"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="ArimoItalic"]::before
{
    font-family: "ArimoItalic";
    content: "Arimo Italic" !important;
}
.ql-font-ArimoItalic {
  font-family: "ArimoItalic";
}

.ql-picker.ql-font .ql-picker-label[data-value="ArimoBoldItalic"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="ArimoBoldItalic"]::before
{
    font-family: "ArimoBoldItalic";
    content: "Arimo Bold Italic" !important;
}
.ql-font-ArimoBoldItalic {
  font-family: "ArimoBoldItalic";
}

.ql-picker.ql-font .ql-picker-label[data-value="CinzelRegular"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="CinzelRegular"]::before
{
    font-family: "CinzelRegular";
    content: "Cinzel Regular" !important;
}
.ql-font-CinzelRegular {
  font-family: "CinzelRegular";
}

.ql-picker.ql-font .ql-picker-label[data-value="CinzelBold"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="CinzelBold"]::before
{
    font-family: "CinzelBold";
    content: "Cinzel Bold" !important;
}
.ql-font-CinzelBold {
  font-family: "CinzelBold";
}

.ql-picker.ql-font .ql-picker-label[data-value="NunitoRegular"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NunitoRegular"]::before
{
    font-family: "NunitoRegular";
    content: "Nunito Regular" !important;
}
.ql-font-NunitoRegular {
  font-family: "NunitoRegular";
}

.ql-picker.ql-font .ql-picker-label[data-value="NunitoBold"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NunitoBold"]::before
{
    font-family: "NunitoBold";
    content: "Nunito Bold" !important;
}
.ql-font-NunitoBold {
  font-family: "NunitoBold";
}

.ql-picker.ql-font .ql-picker-label[data-value="NunitoLight"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NunitoLight"]::before
{
    font-family: "NunitoLight";
    content: "Nunito Light" !important;
}
.ql-font-NunitoLight {
  font-family: "NunitoLight";
}

.ql-picker.ql-font .ql-picker-label[data-value="NunitoLightItalic"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="NunitoLightItalic"]::before
{
    font-family: "NunitoLightItalic";
    content: "Nunito Light Italic" !important;
}
.ql-font-NunitoLightItalic {
  font-family: "NunitoLightItalic";
}

.ql-picker.ql-font .ql-picker-label[data-value="MerriweatherRegular"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="MerriweatherRegular"]::before
{
    font-family: "MerriweatherRegular";
    content: "Merriweather Regular" !important;
}
.ql-font-MerriweatherRegular {
  font-family: "MerriweatherRegular";
}

.ql-picker.ql-font .ql-picker-label[data-value="MerriweatherBold"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="MerriweatherBold"]::before
{
    font-family: "MerriweatherBold";
    content: "Merriweather Bold" !important;
}
.ql-font-MerriweatherBold {
  font-family: "MerriweatherBold";
}

.ql-picker.ql-font .ql-picker-label[data-value="MontserratRegular"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="MontserratRegular"]::before
{
    font-family: "MontserratRegular";
    content: "Montserrat Regular" !important;
}
.ql-font-MontserratRegular {
  font-family: "MontserratRegular";
}

.ql-picker.ql-font .ql-picker-label[data-value="MontserratBold"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="MontserratBold"]::before
{
    font-family: "MontserratBold";
    content: "Montserrat Bold" !important;
}
.ql-font-MontserratBold {
  font-family: "MontserratBold";
}

.ql-picker.ql-font .ql-picker-label[data-value="ArchivoSemiCondensedRegular"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="ArchivoSemiCondensedRegular"]::before
{
    font-family: "ArchivoSemiCondensedRegular";
    content: "Archivo SemiCondensed Regular" !important;
}
.ql-font-ArchivoSemiCondensedRegular {
  font-family: "ArchivoSemiCondensedRegular";
}

.ql-picker.ql-font .ql-picker-label[data-value="ArchivoSemiCondensedBold"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="ArchivoSemiCondensedBold"]::before
{
    font-family: "ArchivoSemiCondensedBold";
    content: "Archivo SemiCondensed Bold" !important;
}
.ql-font-ArchivoSemiCondensedBold {
  font-family: "ArchivoSemiCondensedBold";
}

/* Custom loader */
.loader {
  opacity: 0.8;
  border: 5px solid #ffffff;
  border-top: 5px solid rgb(179, 169, 152);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 350ms linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.highlight {
  position: relative;
  color: green !important;
  border: 2px solid green !important;
}

.highlight::after {
  content: 'Rush';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  font-size: 11px;
  color: green;
}

.react-calendar__tile--active.highlight {
  color: white !important;
}
.react-calendar__tile--active.highlight::after {
  color: white !important;
}


