@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.up-down-arrow {
  @apply relative before:absolute before:z-[1] before:right-[7.1px] before:top-[2.5px] before:flex before:items-center before:justify-center before:w-[18px] before:h-2.5 before:bg-white before:pointer-events-none before:text-lg before:content-['\25B4'] after:absolute after:right-[7px] after:bottom-[0.5px] after:flex after:items-center after:justify-center after:w-[18px] after:h-2.5 after:bg-white after:pointer-events-none after:content-['\25B4'] after:text-lg after:rotate-180 peer-disabled:before:text-black-200/[.1] peer-disabled:after:text-black-200/[.1];
}